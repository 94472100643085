import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { FaCopy } from 'react-icons/fa';

import Sidebar from '../components/navbar';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';
import loadinggif from '../icons/loading.gif';
import { v4 as uuidv4 } from 'uuid';
import ticked from '../icons/OIP.png';
import { readyException } from "jquery";


function Automation() {
    const [reply, setReply] = useState('');
    const [automationid, setautomationid] = useState('');
    const [tonumber, setTonumber] = useState('');
  const [phonenumbers, setPurchasednumbers] = useState([]); 
  const [number, setNumber] = useState(""); 
    const [webhookname, setWebhookname] = useState(""); // To store the generated URL
    // const [webhookURL, setWebhookURL] = useState(""); // To store the generated URL
    const [encid, setEncid] = useState(""); // To store the generated URL
    const [loading, setLoading] = useState(false); // To manage loading spinner visibility
    // const [loading, setLoading] = useState(false); // To manage loading spinner visibility
    const [isWebhookReady, setIsWebhookReady] = useState(false); // To track if the webhook is ready
    // const [receivedData, setReceivedData] = useState(null);
    const [columnMappings, setColumnMappings] = useState([]);
    const [webhook, setWebhook] = useState([]);
    const [receivedData, setReceivedData] = useState(null);
    const [message, setMessage] = useState(''); 
    const [showRadioButtons, setShowRadioButtons] = useState(false);
    const [selectedChatInbox, setSelectedChatInbox] = useState("");
    const [inboxDisabled, setInboxDisabled] = useState(false);
    const [variableDisabled, setVariableDisabled] = useState(false); // To disable the variable dropdown

    const [dynamicOptions, setDynamicOptions] = useState([]);
    function formatPhoneNumbercrm(phone) {
      const normalizedNumber = phone.replace(/\D/g, "");
  
      if (normalizedNumber.length === 0) return ""; 
      // console.log("ok",normalizedNumber)
      if (phone.startsWith("+1")) {
          return phone.substring(2).replace(/(\d{3})(\d{3})(\d{4})/, '($1)  $2-$3');
      } else {
          return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1)  $2-$3');
      }
  }
  function formatPhoneNumbercrmInboxnmer(phone) {
    if (phone.startsWith("+1")) {
        return phone.substring(2).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
}
const handleWebhookChange = (e) => {
  try{
    const selectedWebhook = webhook.find((item) => item.url === e.target.value);
    setWebhookname(selectedWebhook.url);

    // Dynamically find chatinbox options
    const chatinboxOptions = selectedWebhook.columnMappings.filter((mapping) =>
      mapping.importOption.startsWith("chatinbox")
    );
    setDynamicOptions(chatinboxOptions);
    setShowRadioButtons(chatinboxOptions.length > 0);
    setSelectedChatInbox("");
    setInboxDisabled(false); // Reset the inbox dropdown
  } catch (error) {
    setSelectedChatInbox("");
    setInboxDisabled(false);
    setShowRadioButtons("");
  }
};
const handleInboxSelect = (e) => {
  if (e.target.value === '') {
    setVariableDisabled(false);
    // alert("ds")
    setNumber(e.target.value);

  }else{
  setNumber(e.target.value);

    setVariableDisabled(true);
  }
  // setInboxDisabled(true); // Disable the inbox dropdown when a selection is made
  // setVariableDisabled(false); // Enable the variable dropdown when inbox is selected
};

// Handle Variable Selection
// const handleVariableSelect = (e) => {
//   setSelectedVariable(e.target.value);
//   setVariableDisabled(true); // Disable the variable dropdown when a selection is made
//   setInboxDisabled(false); // Enable the inbox dropdown when variable is selected
// };
    useEffect(() => {
      
      document.body.style.backgroundColor = "rgb(42,45,54)";
    }, []);
  const maxLength = 160;

//   const handleChange = (e) => {
//     const inputValue = e.target.value;
//     if (inputValue.length <= maxLength) {
//       setReply(inputValue);
//     }
// };
  const wordCount = reply.trim().split(/\s+/).filter(Boolean).length;

  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility
  const [repsucc, setRepsucc] = useState('');
     // Function to open the modal
     const [showwebhookModal, setwebhookShowModal] = useState(false); 
    const generateRandomId = () => {
      return uuidv4();  // Generates a unique, random UUID string
    }
    
    // const handlewebhookModalOpen = () => setwebhookShowModal(true);
    const handlewebhookModalOpen = () => {
      setwebhookShowModal(true);
      setLoading(true);
  
      // Simulate URL generation
      setTimeout(() => {
        const siteDomain = `http://localhost:3000`;
        const enid = generateRandomId()
        const newWebhookURL = `${API_BASE_URL}/webhook/${enid}`;
        setEncid(enid)
        // setWebhookURL(newWebhookURL);
        setLoading(false); // Stop loading
        setIsWebhookReady(true); // Webhook is ready
      }, 1500); // Simulate delay
    };
    // const handlewebhookModalClose = () => setwebhookShowModal(false); 
    const [selectedInbox, setSelectedInbox] = useState(""); // Track selected Chat Inbox

    const handlewebhookModalClose = () => {
      setSelectedChatInbox("cleanedInbox");
      setInboxDisabled(false);
      setVariableDisabled(false)
      setDynamicOptions([])
      setShowRadioButtons(false)
      setautomationid('')
      setWebhookname('')
      setMessage('')
      // setReply(reply)
      setNumber('')
      // alert(tonumber)
      setTonumber('')
      setColumnMappings([]);
      setReceivedData(null);
      setwebhookShowModal(false);
      // setWebhookURL(""); // Reset the URL on close
      setLoading(false); // Reset loading state
      setIsWebhookReady(false); // Reset webhook ready state
    };
    const handleChatInboxClick = (inboxName) => {
      if (selectedInbox === inboxName) {
        // If the same Chat Inbox is clicked, unselect it
        setSelectedInbox("");
      } else {
        // Select the clicked Chat Inbox
        setSelectedInbox(inboxName);
        setNumber(""); // Clear the Select Inbox dropdown when a Chat Inbox is selected
      }
    };
  const [authenticated, setAuthenticated] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [replies, setReplies] = useState([]);

  const [id, setUserid] = useState('');
  const [replyname, setReplyname] = useState('');
  const smsParts = Math.ceil(message.length / 160);
  useEffect(() => {
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)

        try {
          const response= await axios.post(`${API_BASE_URL}/api/fetch-automations`, {
            id
          });
          setReplies(response.data);
          const listsResponse = await axios.post(`${API_BASE_URL}/api/fetch-automations-webhook`, { id });
          setWebhook(listsResponse.data);
          const dresponse = await axios.post(`${API_BASE_URL}/api/fetch-numbers`, { id });
          setPurchasednumbers(dresponse.data);
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchreplies(); 
  }, []);
  const editmodalwork = async (name, message,id,inbox,tonumber) => {
    setWebhookname(name)
    setMessage(message)
    // alert(inbox)
    if (typeof inbox === "string" && inbox.includes("chatinbox")) {
      const selectedWebhook = webhook.find((item) => item.url === name);
      setWebhookname(selectedWebhook.url);
    
      // Dynamically find chatinbox options
      const chatinboxOptions = selectedWebhook.columnMappings.filter((mapping) =>
        mapping.importOption.startsWith("chatinbox")
      );
      setDynamicOptions(chatinboxOptions);
      setShowRadioButtons(chatinboxOptions.length > 0);
      const cleanedInbox = inbox.replace(/[{}]/g, "");

      // Set the cleaned inbox value
      setSelectedChatInbox(cleanedInbox);
      setInboxDisabled(true);
      // alert("he")
    }
    
    
    // setReply(reply)
    setautomationid(id)
    setNumber(formatPhoneNumbercrmInboxnmer(inbox))
    // alert(tonumber)
    setTonumber(tonumber === "{{phone}}" ? tonumber : formatPhoneNumbercrm(tonumber))
    handlewebhookModalOpen()
  };
  const getDisabledOptions = () => {
    const selectedOptions = columnMappings.map(mapping => mapping.importOption);
    return selectedOptions.filter(option => option !== 'do_not_import');
  };
   const normalizePhoneNumber = (number) => {
    const digits = number.replace(/\D/g, ''); // Remove all non-digit characters
    const formattedNumber = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    return formattedNumber;
  };
  const handleChange = (e) => {
    const inputValue = e.target.value;
    // if (inputValue.length <= maxLength) {
      setMessage(inputValue
      );
    // }
  };
  const handleChatInboxSelect = (inbox) => {
    if (inbox === '') {
      setSelectedChatInbox("");
      setInboxDisabled(false);
      // alert("ds")

    }else{
      setNumber("")
      setSelectedChatInbox(inbox);
      setInboxDisabled(true);
    }
    // if (selectedChatInbox === inbox) {
    //   // Deselect if already selected
    //   setSelectedChatInbox("");
    //   setInboxDisabled(false);
    //   setVariableDisabled(true)
    // } else {
     
    
  };
  const handleButtonClick = (variable) => {
    const updatedMessage = message + `{{${variable}}}`;
  
      setMessage(updatedMessage);
    
  };
  const fetchPostedData = async () => {
  
    try {
      const siteDomain = `http://127.0.0.1:8000`;
      const formData = {
        url:encid
      }
      const response = await axios.post(`${siteDomain}/api/fetch-webhook-data`, {
        url:encid,
        
      });
      const firstItem = response.data[0];
      console.log(firstItem.data); // Parse the JSON string

      const parsedData = JSON.parse(firstItem.data);
      console.log(parsedData) // Parse the JSON string
      const keys = Object.keys(parsedData); // Extract the keys (e.g., "message", "user_id")
      const initialMappings = keys.map(key => ({
        columnName: key,
        importOption: 'do_not_import', // Default option
      }));
      setColumnMappings(initialMappings); // Set initial mappings
      setReceivedData(parsedData);
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const formatPhoneNumber = (number) => {
    const normalizedNumber = number.replace(/\D/g, "");
  
    if (normalizedNumber.length === 0) return ""; // Handle empty input
    if (normalizedNumber.length <= 3) return `(${normalizedNumber})`; // Ensure both brackets
    if (normalizedNumber.length <= 6) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3)}`;
    if (normalizedNumber.length <= 10) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6)}`;
  
    // Handle numbers longer than 10 digits
    return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6, 10)}`;
  };
  
  const handleDelete = async (reply) => {
    
    setRepsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to delete this ?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-automation/${reply}`);
      
    
      setReplies(prevWebhooks => prevWebhooks.filter(item => item.id !== reply));
      setRepsucc(true)
    } catch (error) {
      setRepsucc(false)

      console.error('Error deleting webhook:', error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    const cleanedNumber = tonumber.replace(/\D/g, "");

  // Check if the cleaned number has exactly 10 digits
  if (!tonumber.includes("{{phone}}")) {
    // alert(cleanesdNumber)
    if (cleanedNumber.length !== 10) {
      alert("The phone number must be exactly 10 digits long.");
      return;
    }
}
  if(selectedChatInbox){
    var inboxnumber = '{{'+selectedChatInbox+'}}'
  }else{
    var inboxnumber = number
    
  }
  // alert(inboxnumber)
    const response= await axios.post(`${API_BASE_URL}/api/insert-automations`, {
      id,
      url:webhookname,
      number:inboxnumber,
      message:message,
      tonumber:tonumber
    });
    alert("Succesfull")
    window.location.reload()
    
  };
  
 
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
<Modal show={showwebhookModal} 
         dialogClassName="modal-fullscreenss"  contentClassName="custom-modal-content" onHide={handlewebhookModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form method="POST" onSubmit={handleSubmit}>
        <Modal.Body>
        
        <div className="text-center">
            {automationid ?(
            <h4>EDIT AUTOMATION</h4>

            ):(
              <h4>CREATE AUTOMATION</h4>

            )
          }
        </div>
        <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label">Select Webhook: </label>
                <select
                  id="lists"
                  name="webhook"
                  value={webhookname}
                  className="form-select custom-dorpdown-toglle"
                  onChange={handleWebhookChange}
                  required
                >
                  <option value="" >Select Webhook</option>
                  {webhook.map(item => (
                    <option key={item.name} value={item.url}>{item.name}</option>
                  ))}
                </select>
              </div>      
              <div className="container">
  <div className="row">
    {/* Select Inbox Dropdown */}
    <div className={` ${showRadioButtons ? 'col-6' : ''}`}>
      <div className="form-group">
        <label htmlFor="recipient-name" className="col-form-label">
          Select Inbox:
        </label>
        <select
          id="list"
          name="list"
          value={number}
          disabled={inboxDisabled}
          className="form-select custom-dorpdown-toglle"
          onChange={handleInboxSelect}
          required={!showRadioButtons} 
        >
          <option value="">Select Inbox</option>
          {phonenumbers.map((item) => (
            <option key={item.name} value={item.number}>
              {item.name} {item.number}
            </option>
          ))}
        </select>
      </div>
    </div>

    {/* Select Variable Dropdown (only if showRadioButtons is true) */}
    {showRadioButtons && (
      <div className=" col-6">
        <div className="form-group">
          <label htmlFor="chatinbox" className="col-form-label">
            Or Select Inbox Variable:
          </label>
          <div style={{ display: 'flex' }}>
            <select
              id="list"
              name="list"
              disabled={variableDisabled}
              value={selectedChatInbox}
              className="form-select custom-dorpdown-toglle"
              onChange={(e) => handleChatInboxSelect(e.target.value)}
              required={showRadioButtons}
            >
              <option value="">Select Variable</option>
              {dynamicOptions.map((item) => (
                <option key={item.importOption} value={item.importOption}>
                  {item.importOption.replace(/(\d+)/g, ' $1')}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    )}
  </div>
</div>


              
          <div className="container">
            <div className="row">
              
          
              <div className="col-10">
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">To Number: </label>
                  {/* <input type="text" placeholder="To Number" className="form-control"  value={formatPhoneNumber(tonumber)} required onChange={(e) => setTonumber(e.target.value)}  /> */}
                  <input
            type="text"
            placeholder="To Number"
            className="form-control"
            value={tonumber === "{{phone}}" ? tonumber : formatPhoneNumber(tonumber)}
            required
            onChange={(e) => setTonumber(e.target.value)}
          />
                </div>         
              </div> 
              <div className="col-2 mt-4">
       
       <div className="form-group mt-3">
         <div className="disabl_field">
           <div
             className="form-group d-flex mb-1"
             style={{ placeContent: "space-evenly" }}
           >
             <input
               type="button"
               className="custom-cls-bg"
               value="Phone"
               id="first_name"
               onClick={() => setTonumber("{{phone}}")}
             />
            
           </div>
          
           
         </div>
       </div>
     </div>        
            </div>         
          </div>         
          <div className="container">
          <div className="row">
             
         
            <div className="col-8">
        <div
          className="mt-3 mb-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <label>Message:</label>
          <div style={{ textAlign: "right" }}>
            <label className="def-countl">
              {message.length} Characters&nbsp;&nbsp; 
              <span>({smsParts} SMS Part{smsParts > 1 ? "s" : ""})</span>
            </label>
          </div>
        </div>
        <div className="form-group">
          <textarea
            id="message-area"
            type="text"
            value={message}
            onChange={(e) => handleChange(e)}
            className="form-control mb-4"
            name='message'
            style={{
              height: "103px",
              backgroundColor: "#585858ba",
            }}
            required
          />
        </div>
      </div>
      <div className="col-4 mt-4">
       
        <div className="form-group mt-3">
          <div className="disabl_field">
            <div
              className="form-group d-flex mb-1"
              style={{ placeContent: "space-evenly" }}
            >
              <input
                type="button"
                className="custom-cls-bg"
                value="First Name"
                id="first_name"
                onClick={() => handleButtonClick("first_name")}
              />
              <input
                type="button"
                className="custom-cls-bg"
                value="Last Name"
                id="last_name"
                onClick={() => handleButtonClick("last_name")}
              />
            </div>
            <div
              className="labelCheckList"
              style={{ placeContent: "space-evenly" }}
            >
              <div
                className="form-group d-flex "
                style={{ placeContent: "space-evenly" }}
              >
                <input
                  type="button"
                  className="custom-cls-bg"
                  value="State"
                  id="state"
                  onClick={() => handleButtonClick("state")}
                />
                <input
                  type="button"
                  className="custom-cls-bg"
                  value="City"
                  id="city"
                  onClick={() => handleButtonClick("city")}
                />
              </div>
            </div>
            <div className="labelCheckList">
              <div
                className="form-group d-flex "
                style={{ placeContent: "space-evenly" }}
              >
                <input
                  type="button"
                  className="custom-cls-bg"
                  value="Address"
                  id="address"
                  onClick={() => handleButtonClick("property_address")}
                />
                <input
                  type="button"
                  className="custom-cls-bg"
                  value="Zip"
                  id="zip"
                  onClick={() => handleButtonClick("zip")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>   
      </div>   
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlewebhookModalClose} data-dismiss="modal" >Close</button>
            
              <button type="submit"  className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Saving...' : 'Save'}</button>
            
        </Modal.Footer>
        </form>
      </Modal>
       
        
        <div className="row ">
          <div className="col-3" style={{marginRight: '-60px'}}>
          <Menu/>
    
          </div>
          <div className="col-9" style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
        <div className="tab">
      <div className={`tab-item ${location.pathname === '/sms_settings' ? 'active' : ''}`}>
        <Link to="/sms_settings" className="link disabled">DNC Manager</Link>
        {location.pathname === '/sms_settings' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/auto_dnc' ? 'active' : ''}`}>
      <Link to="/auto_dnc"  className="link disabled">Auto DNC</Link>
        {location.pathname === '/auto_dnc' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/quick_reply' ? 'active' : ''}`}>
      <Link to="/quick_reply"  className="link disabled">Quick Reply</Link>
        {location.pathname === '/quick_reply' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/automation' ? 'active' : ''}`}>
      <Link to="/automation"  className="link disabled">Automation</Link>
        {location.pathname === '/automation' && <div className="strip"></div>}
      </div>
      {/* <div className={`tab-item ${location.pathname === '/sms_template' ? 'active' : ''}`}>
      <Link to="/sms_template"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_template' && <div className="strip"></div>}
      </div> */}
      
    </div>
    </div>
        
        <div className="userEditForm mt-4">
            <h5>&nbsp;Automation</h5>
        {repsucc && <div className="col-12 mb-3" style={{color: '#65ff16'}}>Automation Deleted Successfuly !</div>}

            
            <div className=" mb-2" style={{textAlign:'right'}}>
          
            <button type="button" className="btn btn_primary save_btn" onClick={handlewebhookModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;CREATE AUTOMATION
          </button>
          </div>
          {replies.length === 0 && <div className="col-md-12 text-center">
      <div className="mb-2 ml-2" style={{color:'white',fontSize:'25px'}}>No Automation Found</div>
            </div>}
          <div class="table-responsive text-nowrap mt-3">
          {replies && replies.some((item) => item.message) && (
  <table className="table">
    <thead>
      <tr>
        <th style={{ padding: '0px' }}>Webhook</th>
        <th style={{ padding: '0px' }}>Inbox-Number</th>
        <th style={{ padding: '0px' }}>Message</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody className="table-border-bottom-0">
      {replies.map((item) => (
        item.message && ( // Render rows only if `item.message` exists
          <tr key={item.id}>
            <td style={{ padding: '0px', textWrap: 'auto' }}>{item.name}</td>
            <td>{formatPhoneNumbercrm(item.inbox)}</td>
            <td style={{ textWrap: 'auto' }}>{item.message}</td>
            <td style={{ textWrap: 'auto' }}>    <FaEdit
                className="mt-3 "
                onClick={() => editmodalwork(item.url, item.message, item.id,item.inbox,item.tonumber)}
              />
              </td>
            <td>
        
              &nbsp;&nbsp;
              <img
                src={deletes}
                className="mt-3"
                alt="Delete"
                onClick={() => handleDelete(item.id)}
              />
            </td>
          </tr>
        )
      ))}
    </tbody>
  </table>
)}

   
  </div>
        </div>
        
        </div>
          
      </div>
      
        </Sidebar>
        );
      }
    
}

export default Automation;
