import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { FaCopy } from 'react-icons/fa';

import Sidebar from '../components/navbar';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';
import loadinggif from '../icons/loading.gif';
import { v4 as uuidv4 } from 'uuid';
import ticked from '../icons/OIP.png';


function Textbee() {
    const [reply, setReply] = useState('');
    const [gatewayid, setgatewayid] = useState('');
    const [name, setgatewayname] = useState('');
    const [phone, setphone] = useState('');
    const [api, setapi] = useState('');
    const [device, setdevice] = useState('');
    const [webhookURL, setWebhookURL] = useState(""); // To store the generated URL
    const [encid, setEncid] = useState(""); // To store the generated URL
    const [loading, setLoading] = useState(false); // To manage loading spinner visibility
    // const [loading, setLoading] = useState(false); // To manage loading spinner visibility
    const [isWebhookReady, setIsWebhookReady] = useState(false); // To track if the webhook is ready
    // const [receivedData, setReceivedData] = useState(null);
    const [columnMappings, setColumnMappings] = useState([]);
    const [receivedData, setReceivedData] = useState(null);
    const [showcontacteditmodal, setcontactshoweditmodal] = useState(false);
const handlecontacteditModalOpen = (id,name,phone,api,dev) => {
  
  setgatewayid(id)
  setgatewayname(name)
  setphone(phone)
  setapi(api)
  setdevice(dev)
  setcontactshoweditmodal(true);
};
const handlecontacteditModalClose = () => {
  setcontactshoweditmodal(false)
}
    useEffect(() => {
      
      document.body.style.backgroundColor = "rgb(42,45,54)";
    }, []);
  const maxLength = 160;
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value); // Format the input value
    setPhoneNumber(formattedNumber); // Update state
  };
  const wordCount = reply.trim().split(/\s+/).filter(Boolean).length;

  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility
  const [repsucc, setRepsucc] = useState('');
     // Function to open the modal
     const [showwebhookModal, setwebhookShowModal] = useState(false); 
    const generateRandomId = () => {
      return uuidv4();  // Generates a unique, random UUID string
    }
    
    // const handlewebhookModalOpen = () => setwebhookShowModal(true);
    const handlewebhookModalOpen = () => {
      setwebhookShowModal(true);
      setLoading(true);
  
      // Simulate URL generation
      setTimeout(() => {
        const siteDomain = `http://localhost:3000`;
        const enid = generateRandomId()
        const newWebhookURL = `${API_BASE_URL}/webhook/${enid}`;
        setEncid(enid)
        setWebhookURL(newWebhookURL);
        setLoading(false); // Stop loading
        setIsWebhookReady(true); // Webhook is ready
      }, 1500); // Simulate delay
    };
    // const handlewebhookModalClose = () => setwebhookShowModal(false); 
    
    const handlewebhookModalClose = () => {
      setColumnMappings([]);
      setReceivedData(null);
      setwebhookShowModal(false);
      setWebhookURL(""); // Reset the URL on close
      setLoading(false); // Reset loading state
      setIsWebhookReady(false); // Reset webhook ready state
    };

  const [authenticated, setAuthenticated] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [replies, setReplies] = useState([]);

  const [id, setUserid] = useState('');
  const [replyname, setReplyname] = useState('');
  
  useEffect(() => {
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      setcontactshoweditmodal()
      if (id) {
        setAuthenticated(true)
        setUserid(id)

        try {
          const response= await axios.post(`${API_BASE_URL}/api/fetch-textbee`, {
            id
          });
          setReplies(response.data);
        
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchreplies(); 
  }, []);
 
  
  const handleDelete = async (reply,number) => {
    
    setRepsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to delete?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-textbee/${reply}/${number}`);
      
    
      setReplies(prevWebhooks => prevWebhooks.filter(item => item.id !== reply));
      setRepsucc(true)
    } catch (error) {
      setRepsucc(false)

      console.error('Error deleting webhook:', error);
    }
  };
  
  const formatPhoneNumber = (number) => {
    const normalizedNumber = number.replace(/\D/g, ''); 
    if (normalizedNumber.length <= 3) return `(${normalizedNumber}`;
    if (normalizedNumber.length <= 6) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3)}`;
    if (normalizedNumber.length <= 10) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6)}`;
    return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6, 10)}`;
  };
 
  const formatPhoneNumbers = (number) => {
    // Remove non-numeric characters and remove the first digit (country code)
    const normalizedNumber = number.replace(/\D/g, '').slice(1); // Remove non-digits and slice off the first digit
    
    if (normalizedNumber.length <= 3) return `(${normalizedNumber}`;
    if (normalizedNumber.length <= 6) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3)}`;
    if (normalizedNumber.length <= 10) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6)}`;
    return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6, 10)}`;
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target);
    formData.append('id', id);
    const serializedData = Object.fromEntries(formData.entries());
    // serializedData.append(id)
    // console.log("Serialized Data:", serializedData);
    // const siteDomain = `http://127.0.0.1:8000`;
    const response= await axios.post(`${API_BASE_URL}/api/set-textbee`, {
      serializedData
    });
   
    alert("Succesfuly Created !");
    window.location.reload()
  };
  
  
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
           <Modal show={showcontacteditmodal} contentClassName="custom-modal-content" onHide={handlecontacteditModalClose} backdropClassName="custom-backdrop" >
 
 <Modal.Header closeButton>
 </Modal.Header>
 <form
onSubmit={(e) => {
e.preventDefault(); // Prevent default form submission behavior

const formData = {
  id:gatewayid,
  name:name,
  phone:phone,
  api:api,
  device:device,
}
setLoading(true)
setLoading(true)
fetch(`${API_BASE_URL}/api/update-gateway`, {
method: "POST",
headers: {
 "Content-Type": "application/json",
},
 body: JSON.stringify(formData),
})
.then((response) => response.json())
.then((data) => {
  alert("Saved Succesful!")
  window.location.reload()
 // Close modal
})
.catch((error) => {
 console.error("Error saving data:", error);
});
}}
>
<Modal.Body>
 <div className="text-center">
   <h4>EDIT GATEWAY</h4>
 </div>
 <div className="modal-body">
<div className="container mt-3 d-flex">
{/* Left Section */}
<div className="w-100">
<div className="row">
 <div className="form-group">
   <label className="text-white">Name:</label>
   <input
     className="form-control"
     type="text"
     placeholder="First Name"
     value={name}
     id="firstname"
     
     onChange={(e) => setgatewayname(e.target.value)}
   />
 </div>
 <div className="form-group">
   <label className="text-white">Phone:</label>
   <input
     className="form-control"
     type="text"
     placeholder="Last Name"
     value={formatPhoneNumbers(phone)}
     id="lastname"
     onChange={(e) => setphone(e.target.value)}
   />
 </div>
</div>
<div className="row mt-1">
 <div className="form-group">
   <label className="text-white">Api:</label>
   <input
     className="form-control"
     type="text"
     placeholder="Phone"
     value={api}
     id="phone"
     onChange={(e) => setapi(e.target.value)}
     
   />
 </div>
 <div className="form-group">
   <label className="text-white">Device ID:</label>
   <input
     className="form-control"
     type="text"
     placeholder="Address"
     value={device}
     id="address"
     onChange={(e) => setdevice(e.target.value)}
   />
 </div>
</div>


</div>


</div>
</div>

</Modal.Body>
<Modal.Footer className="justify-content-center mb-2">
 <button
   type="button"
   className="btn btn-secondary cancel_btn"
   onClick={handlecontacteditModalClose}
   data-dismiss="modal"
 >
   Close
 </button>
 <button type="submit" className="btn btn-primary save_web_btn">
   UPDATE
 </button>
</Modal.Footer>
</form>

</Modal>
<Modal show={showwebhookModal} 
         contentClassName="custom-modal-content" onHide={handlewebhookModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
        <Modal.Body>
        
        <div className="text-center">
            <h4>ADD GATEWAY</h4>
        </div>
        <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label">Name: </label>
                <input type="text" name="name" className="form-control" id="recipient-name"  required placeholder="Name" />
              </div>      
            <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">API: </label>
            <input type="text" name="api" className="form-control" id="recipient-name"  required placeholder="API KEY"/>
            </div> 
            <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">Device ID: </label>
            <input type="text" name="deviceid" className="form-control" id="recipient-name" required placeholder="Device ID" />
            </div> 
            <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">Phone Number: </label>
            <input type="text" value={phoneNumber} // Bind the value to state
        onChange={handleChange} // Bind value to state
         name="phone" className="form-control" id="recipient-name"  required placeholder="Phone Number" />
            </div> 
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlewebhookModalClose} data-dismiss="modal" >Close</button>
            
              <button type="submit"  className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Saving...' : 'Save'}</button>
            
        </Modal.Footer>
        </form>
      </Modal>
       
        
        <div className="row ">
          <div className="col-3" style={{marginRight: '-60px'}}>
          <Menu/>
    
          </div>
          <div className="col-9" style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
        <div className="tab">
        <div className={`tab-item ${location.pathname === '/integerations' ? 'active' : ''}`}>
      <Link to="/integerations"  className="link disabled">Integerations</Link>
        {location.pathname === '/integerations' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/textbee' ? 'active' : ''}`}>
      <Link to="/textbee"  className="link disabled">Textbee Android SMS Gateway</Link>
        {location.pathname === '/textbee' && <div className="strip"></div>}
      </div>
      {/* <div className={`tab-item ${location.pathname === '/sms_template' ? 'active' : ''}`}>
      <Link to="/sms_template"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_template' && <div className="strip"></div>}
      </div> */}
      
    </div>
    </div>
        
        <div className="userEditForm mt-4">
            <h5>&nbsp;SMS GATEWAY <small>&nbsp;(FOR SMS ONLY)</small></h5>
            <small className="color-white" style={{color:'white'}}>&nbsp;(Doesn't Support Voice or MMS)</small>
        {repsucc && <div className="col-12 mb-3" style={{color: '#65ff16'}}>Gateway Deleted Successfuly !</div>}

            
            <div className=" mb-2" style={{textAlign:'right'}}>
          
            <button type="button" className="btn btn_primary save_btn" onClick={handlewebhookModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;CREATE GATEWAY
          </button>
          </div>
          {replies.length === 0 && <div className="col-md-12 text-center">
      <div className="mb-2 ml-2" style={{color:'white',fontSize:'25px'}}>No Gateway Found</div>
            </div>}
          <div class="table-responsive text-nowrap mt-3">
     <table class="table" >
      <thead>
      {replies.length > 0 &&<tr>
                  <th style={{padding:'0px'}}>Name</th>
                  <th style={{padding:'0px'}}>Phone</th>
                  <th style={{padding:'0px'}}>Api key</th>
                  <th style={{padding:'0px'}}>Device id</th>
                  <th></th>
                  
        </tr>
  }
      </thead>
      <tbody class="table-border-bottom-0">
                     
                  {replies.map((item) => (
          <tr key={item.id}>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.name}</td>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{formatPhoneNumbers(item.phone)}</td>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.apikey}</td>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.deviceid}</td>
        
            <td></td>
            <td></td>
            
            <td>
            <FaEdit style={{cursor:'pointer'}} className="mt-3"  onClick={() => handlecontacteditModalOpen(item.id,item.name,item.phone,item.apikey,item.deviceid)}></FaEdit>&nbsp;&nbsp;
              <img style={{cursor:'pointer'}} src={deletes} className="mt-3" alt="Delete" onClick={() => handleDelete(item.id,item.phone)} />
            </td>
          </tr>
        ))}
                  
                                       </tbody>
    </table> 
   
  </div>
        </div>
        
        </div>
          
      </div>
      
        </Sidebar>
        );
      }
    
}

export default Textbee;
