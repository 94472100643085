import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import ticked from '../icons/OIP.png';

import axios from 'axios';
import Sidebar from '../components/navbar';
import { FaFileExport, FaCog, FaSignOutAlt, FaUber, FaUsers } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import loading from '../icons/loading.gif';
import upload from '../icons/upload.svg';

import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';
function Autodnc() {
  useEffect(() => {

    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility
  const [keyword, setKeyword] = useState(''); // State to control the modal's visibility
  const [showuploadModal, setuploadShowModal] = useState(false); // State to control the modal's visibility
  const [loadingchk, setLoading] = useState(false); // State to control the modal's visibility
  const [fileData, setFileData] = useState(null);
  const [columnMappings, setColumnMappings] = useState([]);
  const [columnNames, setColumnNames] = useState(null);
  const [processing, setProcessing] = useState(false);
    const handleModalOpen = () => setShowModal(true); // Function to open the modal
    const handleModalClose = () => setShowModal(false); // Function to close the modal
  
    const [csvData, setCsvData] = useState([]);
    const handleuploadModalOpen = () => setuploadShowModal(true); // Function to open the modal
    const handleuploadModalClose = () => setuploadShowModal(false); // 

  const [authenticated, setAuthenticated] = useState(null);
  const [id, setUserid] = useState('');
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    const feftchdnc = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)
        try {
          const response= await axios.post(`${API_BASE_URL}/api/fetch-keywords`, {
            id
          });
          setKeywords(response.data);
          
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    feftchdnc(); 
  }, []);
 
  
 
  const exportdnc = async () => {
    

   
    const confirmed = window.confirm('Are you sure you want to Export');
    if (!confirmed) {
      return; 
    }
  
    try {
      setLoading(true)
      const response= await axios.post(`${API_BASE_URL}/api/export-dnc`, {
        id
      });
      const data = response.data; // Assume response.data is an array of objects like [{ phone: "+16152601628" }, ...]

      // Convert data to CSV format
      let csvContent = "DNC NUMBERS\n"; // Add the header line
      data.forEach(item => {
        csvContent += `${item.phone}\n`;
      });
      
      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      
      // Create a link element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'DNC_EXPORT.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
    setLoading(false)
      // setKeywords(prevnumber => prevnumber.filter(item => item.number !== number));
    } catch (error) {
      setLoading(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber == null) {
        return null; // Return null for null or undefined values
    }

    const digits = String(phoneNumber).replace(/\D/g, ''); // Ensure phoneNumber is a string and remove non-digit characters
    const countryCode = '+1';

    if (String(phoneNumber).startsWith('+1')) {
      // alert(phoneNumber)
        // If the number is already in international format with country code +1, keep it as is
        return phoneNumber;
    } else if (digits.length === 10) {
        // Add +1 to 10 digit numbers
        return countryCode + digits;
    } else if (digits.length > 10) {
        // Add +1 to numbers with more than 10 digits
        return countryCode + digits.slice(-10);
        // return countryCode + digits.slice(0);
    } else {
        return null; // Invalid phone number format
    }
};
  const uploadkeywords = async  (event) => {
    setLoading(true)
event.preventDefault()
  
    

  
      const response= await axios.post(`${API_BASE_URL}/api/insert-dnc`, {
        id:id,
        keyword:keyword
      });
      alert("Keyword Added Succesfully")
      window.location.reload()
}
  const handleCsvDelete = () => {
    // Reset fileData state and column mappings
    setFileData(null);
    setColumnMappings([]);
    
  };
  const handleDelete = async (ids) => {
    

   
    const confirmed = window.confirm('Are you sure you want to delete this?');
    if (!confirmed) {
      return; 
    }
  
    try {
      
      const response= await axios.post(`${API_BASE_URL}/api/delete-autodnc`, {
        id:ids
      });
      alert("Deleted Successfuly")
      window.location.reload()
      // setKeywords(prevnumber => prevnumber.filter(item => item.number !== number));
    } catch (error) {

      // console.error('Error deleting webhook:', error);
    }
  };
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
             {loadingchk && (
          <>
        <div class="loading-container">
    <img src={loading} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
  <Modal show={showuploadModal} contentClassName="custom-modal-content" onHide={handleuploadModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit=
         {(event) => uploadkeywords(event)}>
          {/* <form> */}
        <Modal.Body>
        <div className="text-center">
            <h4>Add Keyword</h4>
        </div>
        <div className="modal-body ">
        
              
              
        <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Keyword:</label>
                <textarea type="text" className="form-control" id="recipient-name"  required value={keyword} onChange={(e) => setKeyword(e.target.value)}  />

              </div>
          </div>
        </Modal.Body>
       
          <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleuploadModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn"  disabled={processing}>{processing ? 'Submitting....' : 'Submit'}</button>
        </Modal.Footer>
       
        </form>
      </Modal>
   <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
            <h4>CREATE REPLY</h4>
        </div>
        <div className="modal-body ">
            <form>
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">Name:</label>
                <input type="text" className="form-control" id="recipient-name"  />
              </div>
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Reply:</label>
                <textarea type="text" name="type" className="form-control"  />

              </div>
              {/* <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Type:</label>
                <input type="text" name="type" className="form-control" value="Local" id="recipient-name"  readOnly />

              </div>
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Area Code:</label>
                <input type="text" className="form-control" id="recipient-name"  />

              </div> */}
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="button" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer>
      </Modal>
       
          
        <div className="row ">
          <div className="col-3" style={{marginRight: '-60px'}}>
          <Menu/>
    
          </div>
          <div className="col-9" style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
        <div className="tab">
      <div className={`tab-item ${location.pathname === '/sms_settings' ? 'active' : ''}`}>
        <Link to="/sms_settings" className="link disabled">DNC Manager</Link>
        {location.pathname === '/sms_settings' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/auto_dnc' ? 'active' : ''}`}>
      <Link to="/auto_dnc"  className="link disabled">Auto DNC</Link>
        {location.pathname === '/auto_dnc' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/quick_reply' ? 'active' : ''}`}>
      <Link to="/quick_reply"  className="link disabled">Quick Reply</Link>
        {location.pathname === '/quick_reply' && <div className="strip"></div>}
      </div>
       <div className={`tab-item ${location.pathname === '/automation' ? 'active' : ''}`}>
      <Link to="/automation"  className="link disabled">Automation</Link>
        {location.pathname === '/automation' && <div className="strip"></div>}
      </div> 
      
      {/* <div className={`tab-item ${location.pathname === '/sms_template' ? 'active' : ''}`}>
      <Link to="/sms_template"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_template' && <div className="strip"></div>}
      </div> */}
      
    </div>
    </div>
        
        <div className="userEditForm mt-4">
            <h5>&nbsp;Auto DNC</h5>
             
            <div className=" mb-2" style={{textAlign:'right'}}>
          
             
            <button type="button" onClick={handleuploadModalOpen} className="btn btn_primary save_btn" data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Add Keywords
          </button>&nbsp;&nbsp;
         
      </div>
            {/* <div className=" mb-2" style={{textAlign:'right'}}>
          
          <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Reply
          </button>
          </div> */}
          {keywords.length > 0  ? (
  <div class="table-responsive text-nowrap mt-3">
  <table class="table" >
    <thead>
      <tr>
        {/* <th>Id</th> */}
                <th>Keyword</th>
                <th>Action</th>
                {/* <th>List</th> */}
      </tr>
    </thead>
    <tbody class="table-border-bottom-0">
                               
                {keywords.map((item) => (
        <tr key={item}>
                    <td>{item.keyword}</td>
         
          <td>
            {/* <img src={deletes} className="mt-3" alt="Delete"  /> */}
            <img src={deletes} className="mt-3" alt="Delete" onClick={() => handleDelete(item.id)} />
          </td>
        </tr>
      ))}
                
                                      </tbody>
  </table>
  
</div>
          ):(
            <p className="mb-2 ml-2 text-center mt-3" style={{color:'white',fontSize:'17px'}}>No Keyword Found</p>
          
          )}
        
        </div>
        
        </div>
          
      </div>
     
          {/* Additional content for Page 1 */}
        </Sidebar>
        );
      }
    
}

export default Autodnc;
