import React, { useEffect, useState } from "react";
import { Link, useNavigate,useLocation  } from 'react-router-dom';

import {Modal,Button} from 'react-bootstrap';
import { FaChevronDown,FaUser,FaPlug,FaComment,FaUserCircle, FaCog, FaSignOutAlt, FaUber, FaUsers } from 'react-icons/fa';
import profile from '../icons/profile.svg';
import chaticon from '../icons/chat-icon.svg';
import upload from '../icons/upload.svg';
import axios from 'axios';
import API_BASE_URL from '../components/config';

// import '../css/theme-default.css';
function Menu() {
    const location = useLocation();
    const [showModal, setShowModal] = useState(false); // State to control the modal's visibility
    const [processing, setProcessing] = useState(false);
    const [profilePic, setProfilePic] = useState(null);
    const [showticketimage, setshowProfilePic] = useState(null);
    const handleModaltickeopen = () => setShowModal(true); // Function to open the modal
    const handleModalClose = () => setShowModal(false); // Function to close the modal
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [id, setUserid] = useState('');
    useEffect(() => {
        const id = sessionStorage.getItem("login");
        setUserid(id)
      })  ;
      
  const menuItems = [
    { title: 'User Profile', path: '/settings' },
    { title: 'Billing', path: '/billing' },
    { title: 'Sms Settings', path: '/sms_settings' } ,
    // { title: 'DNC Manager', path: '/dncmanager' } ,

    { title: 'DATA MANAGEMENT', path: '/data-management' },

    { title: 'INTEGRATIONS', path: '/integerations' },
    { title: 'Numbers', path: '/numbers' },
    { title: 'Support', path: '/support' } ,
  ];
    
  const isLeadStatusActive = () => {
    return location.pathname.includes('/lead-status');
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(file);
        setshowProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }
  const ticketSubmit = async (id,subject,message,event) => {
    setProcessing(true)
    event.preventDefault()
    

    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('message', message);
    
    formData.append('id', id);
    // formData.append('attachment', profilePic);
    setProcessing(true)
   
    try {
      const response = await axios.post(`${API_BASE_URL}/api/insert-ticket`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
   
    // try {
    //   const response= await axios.post(`${API_BASE_URL}/api/insert-ticket`, {
    //     id,
    //     subject,
    //     message,
    //   });
    setProcessing(false)

      if (response.data){
        
        
        setShowModal(false);
      }
      // setRepsucc(false)
    } catch (error) {
      setProcessing(false)

   

      console.error('Error deleting webhook:', error);
    }
  };
  const handleRemoveImage = () => {
    setProfilePic(null);
    setshowProfilePic(null);
  };

  return (
    
    <div className="setting-menu">
        <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit=
         {(event) => ticketSubmit(id, subject, message, event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>Submit Ticket</h4>
        </div>
        <div className="modal-body ">
        
              
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Subject:</label>
                <input type="text" name="type" placeholder="Subject" required value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" id="recipient-name"   />

              </div>
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Message:</label>
                <textarea type="text" className="form-control" id="recipient-name"  required value={message} onChange={(e) => setMessage(e.target.value)}  />

              </div>
              {/* <div className=" form-group">
              {showticketimage && (
        <>
          <div style={{ textAlign: 'center', marginTop: '10px', position: 'relative', display: 'inline-block' }}>
            <img src={showticketimage} style={{ width: '100px', height: '100px' }} alt="Profile" />
            <button
              onClick={handleRemoveImage}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                backgroundColor: '#fab43d',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                cursor: 'pointer',
                width: '20px',
                height: '20px',
                textAlign: 'center',
                lineHeight: '20px',
              }}
            >
              X
            </button>
          </div>
          <br />
        </>
      )}
              </div>
              <div className=" form-group mt-4">
          <div className="upload-container">
            <input type="file" id="upload" accept="image/*" style={{ display: 'none' }} onChange={handleFileChange} />
            <label htmlFor="upload" className="upload-button">
            <img src={upload} alt="Upload Icon" class="upload-icon"></img>
              ATTACHMENT
            </label>
          </div>
        </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Submitting....' : 'Submit'}</button>
        </Modal.Footer>
        </form>
      </Modal>
      <div className="acc-manager">
        <p className="title">Customer Support</p>
        <div className="manager-profile">
          <img src={profile} style={{maxWidth: '100%' }} alt="profile"></img>
        </div>
        <div className="info-detail">
          <a href="mailto:support@prophone.io" className="mailto-manager">support@prophone.io</a>
          <br></br>
          <button className="chat-button" onClick={handleModaltickeopen}><img src={chaticon} style={{marginRight: '8px'}} alt="chat-icon"></img>Submit Ticket</button>
        </div>
      </div>
      <div className="setting_mnu mt-4">
        <ul className="list-unstyled">
          {menuItems.map((item, index) => (
            // <li key={index} className={
            //   (location.pathname === item.path || location.pathname == '/lead-status' && item.title == 'DATA MANAGEMENT' || location.pathname == '/tags' && item.title == 'DATA MANAGEMENT'  )? 'active' : ''
            // }>
            //   <Link to={item.path} title={item.title}>{item.title}</Link>
            // </li>
            <li
  key={index}
  className={
    location.pathname === item.path ||
    (location.pathname === '/lead-status' &&
      item.title === 'DATA MANAGEMENT') ||
    (location.pathname === '/tags' && item.title === 'DATA MANAGEMENT') ||
    (location.pathname === '/quick_reply' && item.title === 'Sms Settings')||
    (location.pathname === '/create_template' && item.title === 'Sms Settings') ||
    (location.pathname === '/sms_template' && item.title === 'Sms Settings') ||
    (location.pathname === '/auto_dnc' && item.title === 'Sms Settings') ||
    (location.pathname === '/automation' && item.title === 'Sms Settings') ||
    (location.pathname === '/textbee' && item.title === 'INTEGRATIONS')
      ? 'active'
      : ''
  }
>
  <Link to={item.path} title={item.title}>
    {item.title}
  </Link>
</li>

          ))}
        </ul>
      </div>
    </div>
  );
};

export default Menu;
// export default Sidebar;
