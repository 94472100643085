import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';

import Sidebar from '../components/navbar';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';


function Quickreply() {
    const [reply, setReply] = useState('');
    const [replyid, setReplyid] = useState('');
    
    useEffect(() => {
      
      document.body.style.backgroundColor = "rgb(42,45,54)";
    }, []);
  // const maxLength = 160;

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // if (inputValue.length <= maxLength) {
      setReply(inputValue);
    // }
};
  const wordCount = reply.trim().split(/\s+/).filter(Boolean).length;

  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility
  const [repsucc, setRepsucc] = useState('');
     // Function to open the modal
    const handleModalClose = () => setShowModal(false); // Function to close the modal
    const handleModalOpen = () => {
      setRepsucc(false);
      setShowModal(true);
      setReply('')
      setReplyid('')
      setReplyname('')
    };
    const handleModalOpenBycreation = () => {
      setRepsucc(false);
      setShowModal(true);
    };
  const [authenticated, setAuthenticated] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [replies, setReplies] = useState([]);

  const [id, setUserid] = useState('');
  const [replyname, setReplyname] = useState('');
  
  useEffect(() => {
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)

        try {
          const response= await axios.post(`${API_BASE_URL}/api/fetch-replies`, {
            id
          });
          setReplies(response.data);
        
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchreplies(); 
  }, []);
  
const editmodalwork = async (replyname, reply,id,media) => {
  setShowProfilePic(media)
  setReplyname(replyname)
  setReply(reply)
  setReplyid(id)
  handleModalOpenBycreation()
};
const smsParts = Math.ceil(reply.length / 160);
const addreply = async (id, replyname,reply,event) => {
  event.preventDefault();
    setProcessing(true)
    setRepsucc(false)
    

    try {
      // Send POST request to add a list
      const formData = new FormData();
      formData.append("id", id);
      formData.append("replyname", replyname);
      formData.append("reply", reply);
      formData.append("replyid", replyid);
      if (profilePic) {
        formData.append("image", profilePic);
      }  
      const response = await axios.post(`${API_BASE_URL}/api/add-reply`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setProcessing(false)
      setShowModal(false);
      const newReply = {
        id: response.data,
        replyname: replyname, 
        reply: reply, 
      };
      // const updaetReply = replies ? [...replies, newReply] : [newReply];
      // setReplies(updaetReply);
      if (replyid) {
        setReplies(prevReplies =>
          prevReplies.map(item => item.id === replyid ? newReply : item)
        );
      } else {
        // Append new reply
        setReplies(prevReplies => [...prevReplies, newReply]);
      }
    } catch (error) {
      setProcessing(false)

      console.error('Error adding Replies:');
    }
  };
  const [profilePic, setProfilePic] = useState(null);
const [showProfilePic, setShowProfilePic] = useState(null);

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfilePic(file);
      setShowProfilePic(reader.result);
    };
    reader.readAsDataURL(file);
  }
};

const handleRemoveImage = () => {
  setProfilePic(null);
  setShowProfilePic(null);
};
  const handleDelete = async (reply) => {
    
    setRepsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to delete this Reply?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-replies/${reply}`);
      
    
      setReplies(prevWebhooks => prevWebhooks.filter(item => item.id !== reply));
      setRepsucc(true)
    } catch (error) {
      setRepsucc(false)

      console.error('Error deleting webhook:', error);
    }
  };
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
   <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => addreply(id, replyname, reply,event)}>
        <Modal.Body>
        <div className="text-center">
        <h4>{replyid ? 'UPDATE REPLY' : 'CREATE REPLY'}</h4>
        <p className="def-countl">&nbsp;Disclaimer : <span >Carriers charge by SMS part. Every part is composed of 160 characters and counts as 1 message</span></p>

        </div>
        <div className="modal-body ">
           
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">Name:</label>
                <input type="text" placeholder="Reply Name" required value={replyname} onChange={(e) => setReplyname(e.target.value)} className="form-control" id="recipient-name"  />
              </div>
              <div className="form-group">
                <div className="mt-3 mb-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    
                    <label >Reply:</label>
                        
                    <div style={{textAlign:'right'}}>
                    <label className='def-countl'  >
                    {reply.length} &nbsp;&nbsp; 
                    <span>Characters</span></label>&nbsp;&nbsp; 
                    <span>({smsParts} SMS Part{smsParts > 1 ? "s" : ""})</span>
                    </div>
                </div>
                
        <textarea
          type="text"
          name="type"
          className="form-control"
          value={reply}
          onChange={handleChange}
          required />
              </div>
             
            
          </div>
          <div className="form-group mt-3">
            <label htmlFor="profile-pic" className="mb-2">
              Attachments (Optional):
            </label>
            <input
              type="file"
              id="profile-pic"
              accept="image/*"
              className="form-control"
              onChange={handleFileChange}
            />
          </div>
          {showProfilePic && (
            <>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <img
                  src={showProfilePic}
                  style={{ width: "100px", height: "100px" }}
                  alt="Profile"
                />
                <button
                  onClick={handleRemoveImage}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "#fab43d",
                    color: "white",
                    border: "none",
                    borderRadius: "50%",
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                    textAlign: "center",
                    lineHeight: "20px",
                  }}
                >
                  X
                </button>
              </div>
              <br />
            </>
          )}
        </Modal.Body>
        
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Saving....' : 'Save'}</button>
        </Modal.Footer>
        </form>
      </Modal>
       
        
        <div className="row ">
          <div className="col-3" style={{marginRight: '-60px'}}>
          <Menu/>
    
          </div>
          <div className="col-9" style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
        <div className="tab">
      <div className={`tab-item ${location.pathname === '/sms_settings' ? 'active' : ''}`}>
        <Link to="/sms_settings" className="link disabled">DNC Manager</Link>
        {location.pathname === '/sms_settings' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/auto_dnc' ? 'active' : ''}`}>
      <Link to="/auto_dnc"  className="link disabled">Auto DNC</Link>
        {location.pathname === '/auto_dnc' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/quick_reply' ? 'active' : ''}`}>
      <Link to="/quick_reply"  className="link disabled">Quick Reply</Link>
        {location.pathname === '/quick_reply' && <div className="strip"></div>}
      </div>
       <div className={`tab-item ${location.pathname === '/automation' ? 'active' : ''}`}>
      <Link to="/automation"  className="link disabled">Automation</Link>
        {location.pathname === '/automation' && <div className="strip"></div>}
      </div> 
      {/* <div className={`tab-item ${location.pathname === '/sms_template' ? 'active' : ''}`}>
      <Link to="/sms_template"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_template' && <div className="strip"></div>}
      </div> */}
      
    </div>
    </div>
        
        <div className="userEditForm mt-4">
            <h5>&nbsp;Quick Reply</h5>
        {repsucc && <div className="col-12 mb-3" style={{color: '#65ff16'}}>Reply Deleted Successfuly !</div>}

            
            <div className=" mb-2" style={{textAlign:'right'}}>
          
          <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Reply
          </button>
          </div>
          {replies.length === 0 && <div className="col-md-12 text-center">
      <div className="mb-2 ml-2" style={{color:'white',fontSize:'25px'}}>No Reply Found</div>
            </div>}
          <div class="table-responsive text-nowrap mt-3">
    <table class="table" >
      <thead>
      {replies.length > 0 &&<tr>
          {/* <th>Id</th> */}
                  <th style={{padding:'0px'}}>Name</th>
                  <th style={{padding:'0px'}}>Reply</th>
                  <th style={{padding:'0px'}}>Image</th>
                  <th></th>
                  
        </tr>
  }
      </thead>
      <tbody class="table-border-bottom-0">
                     
                  {replies.map((item) => (
          <tr key={item.id}>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.replyname}</td>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.reply}</td>
            <td><a href={item.media} target="_blank"> <img style={{width: '7rem'}} src={item.media}></img> </a></td>
            <td></td>
            <td>
            <FaEdit
                className="fs-5 mr-2 mt-3"
                onClick={() => editmodalwork(item.replyname, item.reply, item.id,item.media)}
              />&nbsp;&nbsp;
              {/* <FaEdit class="fs-5 mr-2 mt-3" onClick={editmodalwork}></FaEdit> */}
              <img src={deletes} className="mt-3" alt="Delete" onClick={() => handleDelete(item.id)} />
            </td>
          </tr>
        ))}
                  
                                        </tbody>
    </table>
    <div class="pagnination">
          
      </div>
  </div>
        </div>
        
        </div>
          
      </div>
      
        </Sidebar>
        );
      }
    
}

export default Quickreply;
